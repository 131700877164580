export const GATEWAY_BASE_URL = 'https://1dkma6ivy1.execute-api.us-west-2.amazonaws.com/live';
export const CLIENT_BASE_URL = window.location.origin;
export const BUGSNAG_API_KEY = '5bb302d402c71566c1bbbedf53bcb0fa';

export const AWS_CONFIG = {
    "oauth": {
        "domain": "embeddedbistaging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
        ],
        "redirectSignIn": CLIENT_BASE_URL,
        "redirectSignOut": CLIENT_BASE_URL,
        "responseType": "token"
    },
    Auth: {
        mandatorySignIn: true,
        region: "us-west-2",
        userPoolId: "us-west-2_1CWUz2fzb",
        userPoolWebClientId: "5gj0erd1h5tbdctu39jup5i6fm"
    },
};
